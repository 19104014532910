import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { AuthStatus, useAuth } from "../../hooks/useAuth"

interface IProps {}

const Connexion = (props: IProps) => {
  const navigate = useNavigate()
  const { status, login } = useAuth()
  const [inputs, setInputs] = useState({
    email: "",
    password: ""
  })

  const loginForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const email = inputs["email"]
    const password = inputs["password"]
    login(email, password)
  }

  useEffect(() => {
    if (status === AuthStatus.Authenticated) navigate("/")
  }, [status])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e?.target?.name
    const value = e?.target?.value
    setInputs((values) => ({ ...values, [name]: value }))
  }

  return (
    <div className="flex items-center justify-center bg-sumo-100 w-full h-screen">
      <div className="rounded-xl bg-sumo-50 shadow-2xl p-4 pt-8">
        <h1 className="font-semibold text-2xl text-sumo-500  text-center">
          Connectez-vous
        </h1>
        <form className="m-6" onSubmit={loginForm}>
          <h2 className="font-semibold text-lg text-sumo-500">email</h2>
          <input
            className="border-2 border-slate-300 rounded-xl w-72 py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none"
            name="email"
            type="email"
            placeholder="email"
            onChange={handleChange}
          />
          <h2 className="font-semibold text-lg text-sumo-500">mot de passe</h2>
          <input
            className="border-2 border-slate-300 rounded-xl w-72 py-2 px-3 text-gray-700 mb-2 leading-tight focus:outline-none"
            name="password"
            type="password"
            placeholder="mot de passe"
            onChange={handleChange}
          />
          <div className="text-end text-slate-500 text-sm pb-2">
            <a href="/reinit-password">Mot de passe oublié ?</a>
          </div>
          <div className="flex justify-end items-end mt-4">
            <button
              className="bg-sumo-550 hover:opacity-60 rounded-xl py-2 px-4 text-sumo-50 font-semibold"
              type="submit"
            >
              <p>Connexion</p>
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Connexion
