import Cookies from "js-cookie"

let BACKEND_MODE = "PROD"

const baseURL =
  BACKEND_MODE === "PROD"
    ? "https://api-667z3ci5pq-od.a.run.app/api/v1"
    : "http://0.0.0.0:8080/api/v1"

const apiFetchBase = async <T>(
  url: string,
  headers: Headers,
  method?: string,
  body?: Record<string, unknown>,
  params?: {}
): Promise<Response> => {
  const _method = method ? method : body ? "POST" : "GET"
  const _body = body ? JSON.stringify(body) : undefined

  let parameters = ""
  if (params) {
    parameters = Object.entries(params)
      .map(([key, val], idx) => {
        if (idx) return "&" + key + "=" + val
        return "?" + key + "=" + val
      })
      .join(" ")
  }

  const resp = await fetch(baseURL + url + parameters, {
    method: _method,
    redirect: "follow",
    credentials: "include",
    body: _body,
    headers
  })

  if (resp.ok) {
    return resp
  }
  throw new Error(resp.status.toString(), await resp.json())
}

const apiFetchProtected = async <T>(
  url: string,
  callOptions: { body?: Record<string, unknown>; method?: string; params?: {} }
): Promise<T> => {
  const headers = new Headers({
    Authorization: `Bearer ${Cookies.get("sumo_token")}`,
    Accept: "application/json",
    "Content-Type": "application/json"
  })

  const { body, method, params } = callOptions

  return apiFetchBase(url, headers, method, body, params).then((resp) =>
    resp.json()
  )
}

const apiFetch = async <T>(
  url: string,
  callOptions: { body?: Record<string, unknown>; method?: string; params?: {} }
): Promise<T> => {
  const { body, method, params } = callOptions
  const headers = new Headers({
    Accept: "application/json",
    "Content-Type": "application/json"
  })
  return apiFetchBase(url, headers, method, body, params).then((resp) => {
    const token = resp.headers.get("Authorization")?.split(" ")[1]
    if (token) {
      Cookies.set("sumo_token", token, {
        secure: true,
        sameSite: "lax"
      })
    }
    return resp.json()
  })
}

export { apiFetchProtected, apiFetch }
