import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { Suspense, useEffect } from "react"
import {
  Route,
  BrowserRouter as Router,
  Routes,
  redirect
} from "react-router-dom"
import "./App.css"
import Layout from "./components/layout/Layout"
import { AuthStatus, useAuth } from "./hooks/useAuth"
import Connexion from "./views/authent/Connexion"
import PrivateRoute from "./views/authent/PrivateRoute"
import Files from "./views/files/Files"
import Stats from "./views/stats/Stats"

const queryClient = new QueryClient()

const Loader = () => (
  <div className="grid h-screen place-items-center">
    <img src={"images/loading.svg"} className="App-loading" alt="loading" />
    <div>Loading...</div>
  </div>
)
const App = () => {
  const { status, login, authenticate } = useAuth()

  useEffect(() => {
    if (status === AuthStatus.Authenticated) {
      authenticate()
    } else redirect("/login")
  }, [status])

  return (
    <Suspense fallback={<Loader />}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Routes>
            <Route element={<Layout />}>
              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <Stats />
                  </PrivateRoute>
                }
              />
              <Route
                path="/files"
                element={
                  <PrivateRoute>
                    <Files />
                  </PrivateRoute>
                }
              />
            </Route>
            <Route path="/login" element={<Connexion />} />
          </Routes>
        </Router>
      </QueryClientProvider>
    </Suspense>
  )
}

export default App
