import { useCallback } from "react"
import { Account } from "../types/types"
import { apiFetchProtected, apiFetch } from "../utils/api"
import { useAccountStore } from "../utils/store"

export enum AuthStatus {
  Unknown = 0,
  Authenticated = 1,
  Guest = 2
}

export const useAuth = () => {
  const { account, setAccount } = useAccountStore()

  let status
  switch (account) {
    case null:
      status = AuthStatus.Guest
      break
    case undefined:
      status = AuthStatus.Unknown
      break
    default:
      status = AuthStatus.Authenticated
      break
  }

  const authenticate = useCallback(() => {
    apiFetchProtected<Account>("/users/me", {})
      .then(setAccount)
      .catch(() => setAccount(null))
  }, [])

  const login = useCallback((username: string, password: string) => {
    apiFetch<Account>("/users/sign_in", {
      body: { user: { email: username, password } }
    }).then(setAccount)
  }, [])

  const logout = useCallback(() => {
    apiFetch<Account>("/users/sign_out", { method: "DELETE" }).then(() =>
      setAccount(null)
    )
  }, [])

  return {
    status,
    authenticate,
    login,
    logout
  }
}
