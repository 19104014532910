import { useQuery } from "@tanstack/react-query"
import _ from "lodash"
import { IStudy } from "../types/types"
import { apiFetchProtected } from "../utils/api"
import { useAccount } from "./useAccount"

const fecthStudy = async (study_id: string): Promise<IStudy> =>
  apiFetchProtected<IStudy>("/studies/" + study_id, {}).then((data) => {
    return {
      ...data,
      patients: data.patients.sort((a, b) => {
        const a_date = new Date(a.surgery_date)
        const b_date = new Date(b.surgery_date)
        return a_date.valueOf() - b_date.valueOf()
      })
    }
  })

export const orderPatientsByDate = (data: IStudy) => {
  return {
    ...data,
    patients: data.patients.sort((a, b) => {
      const a_date = new Date(a.surgery_date)
      const b_date = new Date(b.surgery_date)
      return b_date.valueOf() - a_date.valueOf()
    })
  }
}

const useStudyQuery = <TData = IStudy>(select?: (data: IStudy) => TData) => {
  const { account } = useAccount()
  return useQuery({
    queryKey: ["study"],
    queryFn: () => fecthStudy(account.study_id.toString()),
    select
  })
}

const usePatientsCount = () =>
  useStudyQuery((data: IStudy) => data.patients.length)

const useScoresCount = () => useStudyQuery((data: IStudy) => data.scores.length)

const useScoresByPatients = () =>
  useStudyQuery((data: IStudy) => {
    const scoresById = _.groupBy(data.scores, "patient_id")
    return scoresById
  })

const useBaselineScoresByPatients = () =>
  useStudyQuery((data: IStudy) => {
    const scoresById = _.groupBy(data.scores, "patient_id")
    const baselineScores = Object.entries(scoresById).map(([key, val]) => {
      return val[val.length - 1]
    })
    return baselineScores
  })

const usePatientsSurgeryDates = () =>
  useStudyQuery((data: IStudy) =>
    _.groupBy(data.patients, ({ surgery_date }) =>
      surgery_date.split("-").slice(0, -1).join("-")
    )
  )

const useSubmissionsCount = () =>
  useStudyQuery((data: IStudy) => data.surveys_answered)

const usePatientsFilter = (filter: string) =>
  useStudyQuery((data: IStudy) => {
    return data.patients.filter((_patient) => {
      return (
        _patient.name.toLowerCase().includes(filter.toLowerCase()) ||
        _patient.surname.toLowerCase().includes(filter.toLowerCase())
      )
    })
  })

export {
  useBaselineScoresByPatients,
  usePatientsCount,
  usePatientsFilter,
  usePatientsSurgeryDates,
  useScoresByPatients,
  useScoresCount,
  useStudyQuery,
  useSubmissionsCount
}
