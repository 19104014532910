import React from "react"

const Footer = () => {
  return (
    <div className="bg-sumo-500">
      <div className="text-white pt-4 py-4 w-full">
        <div className="flex flex-col md:flex-row md:justify-between items-end text-sm text-gray-400 mx-32">
          <p className="">&copy; Cortexx Medical Intelligence 2024</p>
          <div className="text-center text-white">
            <h3 className="text-xl mb-3">Notre application Patient</h3>
            <div className="flex justify-center">
              <div className="flex items-center border w-52 rounded-lg px-4 py-2 mx-2">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/888/888857.png"
                  className="w-7 md:w-8"
                />
                <a
                  className="text-left ml-3"
                  href="https://play.google.com/store/apps/details?id=com.sumoapp&pcampaignid=web_share"
                >
                  <p className="text-xs text-gray-200">Télécharger sur</p>
                  <p className="text-sm md:text-base"> Google Play Store </p>
                </a>
              </div>
              <div className="flex items-center border w-52 rounded-lg px-4 py-2 mx-2">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/888/888841.png"
                  className="w-7 md:w-8"
                />
                <a
                  className="text-left ml-3"
                  href="https://apps.apple.com/us/app/sumoapp/id1556469044"
                >
                  <p className="text-xs text-gray-200">Télécharger sur</p>
                  <p className="text-sm md:text-base"> Apple Store </p>
                </a>
              </div>
            </div>
          </div>
          <div className="">
            <span className="px-2">A propos</span>
            <span className="px-2 border-l">Contactez-nous</span>
            <span className="px-2 border-l">Confidentialité</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
