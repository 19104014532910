import { Chart, registerables } from "chart.js"
import "chart.js/auto"
import _ from "lodash"
import { useEffect, useState } from "react"
import { Bar } from "react-chartjs-2"
import {
  useBaselineScoresByPatients,
  usePatientsCount,
  usePatientsSurgeryDates,
  useScoresCount,
  useSubmissionsCount
} from "../../hooks/useStudies"

Chart.register(...registerables)

const Stats = () => {
  const { data: patientCount } = usePatientsCount()
  const { data: scoresCount } = useScoresCount()
  const { data: surveysAnsweredCount } = useSubmissionsCount()
  const { data: baselineScores } = useBaselineScoresByPatients()
  const { data: patientsByDate } = usePatientsSurgeryDates()

  const [chartData, setChartData] = useState({
    labels: ["1", "2", "3", "4", "5", "6", "7"],
    datasets: [
      {
        label: "Utilisateurs par date de chirurgie",
        data: [65, 59, 80, 81, 56, 55, 40],
        backgroundColor: [
          "rgba(56, 226, 203, 0.2)",
          "rgba(56, 215, 226, 0.2)",
          "rgba(56, 198, 226, 0.2)",
          "rgba(56, 181, 226, 0.2)",
          "rgba(56, 147, 226, 0.2)"
        ],
        borderColor: ["rgba(201, 203, 207)"],
        borderWidth: 1
      }
    ]
  })

  useEffect(() => {
    setChartData({
      labels: patientsByDate ? Object.keys(patientsByDate) : [],
      datasets: [
        {
          label: "Patients par mois",
          data: patientsByDate
            ? Object.entries(patientsByDate).map(([key, val]) => {
                return val.length
              })
            : [],
          backgroundColor: [
            "rgba(56, 226, 203, 0.2)",
            "rgba(56, 215, 226, 0.2)",
            "rgba(56, 198, 226, 0.2)",
            "rgba(56, 181, 226, 0.2)",
            "rgba(56, 147, 226, 0.2)"
          ],
          borderColor: ["rgba(201, 203, 207)"],
          borderWidth: 1
        }
      ]
    })
  }, [patientsByDate])

  return (
    <div className="flex w-full bg-sumo-100 flex-1 flex-wrap">
      <div className="flex w-full flex-row justify-center items-center">
        <section className="flex pt-3 px-3 flex-col">
          <div className="text-slate-900 font-semibold">
            STATISTIQUES PATIENTS
          </div>
          <div className="flex flex-row">
            <div className="flex flex-col">
              <div className="flex w-40">
                <div className="font-bold text-slate-500 mt-2 text-center">
                  Plateforme SuMO
                </div>
              </div>
              <div className="flex w-40 h-32 flex-col rounded-md px-4 py-4 bg-sumo-500 opacity-90 mt-4">
                <div className="font-bold text-5xl text-slate-50">
                  {patientCount}
                </div>
                <div className="font-bold text-slate-50 mt-2">
                  Patients inscrits
                </div>
              </div>
              <div className="flex w-40 h-36 flex-col rounded-md px-4 py-4 bg-sumo-550 opacity-90 mt-4">
                <div className="font-bold text-5xl text-slate-50">
                  {scoresCount}
                </div>
                <div className="font-bold text-slate-50 mt-2">
                  Scores calculés
                </div>
              </div>
              <div className="flex w-40 h-40 flex-col rounded-md px-4 py-4 bg-sumo-500 opacity-90 mt-4">
                <div className="font-bold text-5xl text-slate-50">
                  {surveysAnsweredCount}
                </div>
                <div className="font-bold text-slate-50 mt-2">
                  Questionnaires répondus
                </div>
              </div>
            </div>
            <div className="flex flex-col px-2">
              <div className="flex w-40">
                <div className="font-bold text-slate-500 mt-2 text-center">
                  Scores pré-op
                </div>
              </div>
              <div className="flex w-40 h-40 flex-col rounded-md px-4 py-4 bg-sumo-500 opacity-90 mt-4">
                <div className="font-bold text-5xl text-slate-50">
                  {baselineScores &&
                    100 - _.meanBy(baselineScores, "life_quality")}
                </div>
                <div className="font-bold text-slate-50 mt-2">ODI</div>
              </div>
              <div className="flex w-40 h-36 flex-col rounded-md px-4 py-4 bg-sumo-550 opacity-90 mt-4">
                <div className="font-bold text-5xl text-slate-50">
                  {baselineScores &&
                    _.meanBy(baselineScores, "physical_autonomy")}
                </div>
                <div className="font-bold text-slate-50 mt-2">Autonomie</div>
              </div>
              <div className="flex w-40 h-32 flex-col rounded-md px-4 py-4 bg-sumo-500 opacity-90 mt-4">
                <div className="font-bold text-5xl text-slate-50">
                  {baselineScores &&
                    _.meanBy(baselineScores, "pain_management")}
                </div>
                <div className="font-bold text-slate-50 mt-2">
                  Gestion douleur
                </div>
              </div>
            </div>
            <div className="flex flex-col px-2">
              <div className="flex w-40">
                <div className="font-bold text-slate-500 mt-2 text-center">
                  Scores @3mois
                </div>
              </div>
              <div className="flex w-40 h-36 flex-col rounded-md px-4 py-4 bg-sumo-500 opacity-90 mt-4">
                <div className="font-bold text-5xl text-slate-50">{19.2}</div>
                <div className="font-bold text-slate-50 mt-2">ODI</div>
              </div>
              <div className="flex w-40 h-36 flex-col rounded-md px-4 py-4 bg-sumo-550 opacity-90 mt-4">
                <div className="font-bold text-5xl text-slate-50">{75.6}</div>
                <div className="font-bold text-slate-50 mt-2">Autonomie</div>
              </div>
              <div className="flex w-40 h-36 flex-col rounded-md px-4 py-4 bg-sumo-500 opacity-90 mt-4">
                <div className="font-bold text-5xl text-slate-50">{48.4}</div>
                <div className="font-bold text-slate-50 mt-2">
                  Gestion douleur
                </div>
              </div>
            </div>
            <div className="flex flex-col px-2">
              <div className="flex w-40">
                <div className="font-bold text-slate-500 mt-2 text-center">
                  Scores @6mois
                </div>
              </div>
              <div className="flex w-40 h-32 flex-col rounded-md px-4 py-4 bg-sumo-500 opacity-90 mt-4">
                <div className="font-bold text-5xl text-slate-50">{15.7}</div>
                <div className="font-bold text-slate-50 mt-2">ODI</div>
              </div>
              <div className="flex w-40 h-36 flex-col rounded-md px-4 py-4 bg-sumo-550 opacity-90 mt-4">
                <div className="font-bold text-5xl text-slate-50">{89.1}</div>
                <div className="font-bold text-slate-50 mt-2">Autonomie</div>
              </div>
              <div className="flex w-40 h-40 flex-col rounded-md px-4 py-4 bg-sumo-500 opacity-90 mt-4">
                <div className="font-bold text-5xl text-slate-50">{73.1}</div>
                <div className="font-bold text-slate-50 mt-2">
                  Gestion douleur
                </div>
              </div>
            </div>
            <div
              className="pl-4 justify-center items-center"
              style={{ width: "800px" }}
            >
              <Bar
                data={chartData}
                options={{
                  plugins: {
                    title: {
                      display: true,
                      text: "Patients par date de chirurgie"
                    },
                    legend: {
                      display: false
                    }
                  }
                }}
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Stats
