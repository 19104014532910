import { useQuery } from "@tanstack/react-query"
import { IStudyDetails } from "../types/types"
import { apiFetchProtected } from "../utils/api"
import { useAccount } from "./useAccount"
import _ from "lodash"

const preOpSurveysRef = [
  "personal_lifestyle",
  "professional_lifestyle",
  "medical_history",
  "medical_information",
  "imaging_report"
]

const qOLSurveysRef = ["pain_walk_scale", "life_quality"]

const fecthStudyDetails = async (
  study_id: string,
  patient_id: string
): Promise<IStudyDetails> =>
  apiFetchProtected<IStudyDetails>("/studies/" + study_id, {
    params: {
      patient_id: patient_id,
      completed: "true"
    }
  }).then((data) => {
    return {
      ...data,
      patient_surveys: data.patient_surveys.sort((a, b) => {
        const a_date = new Date(a.submission.created_at)
        const b_date = new Date(b.submission.created_at)
        return b_date.valueOf() - a_date.valueOf()
      })
    }
  })

const useStudyQueryDetails = <TData = IStudyDetails>(
  patient_id: string,
  select?: (data: IStudyDetails) => TData
) => {
  const { account } = useAccount()
  return useQuery({
    queryKey: ["studydetails", patient_id],
    queryFn: () => fecthStudyDetails(account.study_id.toString(), patient_id),
    select
  })
}

const usePreOpSurveys = (patient_id: string) =>
  useStudyQueryDetails(patient_id, (data: IStudyDetails) =>
    data.patient_surveys.filter((patient_survey) =>
      preOpSurveysRef.includes(patient_survey.survey_reference)
    )
  )

const useQOLSurveys = (patient_id: string) =>
  useStudyQueryDetails(patient_id, (data: IStudyDetails) =>
    data.patient_surveys.filter((patient_survey) =>
      qOLSurveysRef.includes(patient_survey.survey_reference)
    )
  )

const usePostOpSurveys = (patient_id: string) =>
  useStudyQueryDetails(patient_id, (data: IStudyDetails) =>
    data.patient_surveys.filter(
      (patient_survey) =>
        !(
          preOpSurveysRef.includes(patient_survey.survey_reference) ||
          qOLSurveysRef.includes(patient_survey.survey_reference)
        )
    )
  )
export {
  useStudyQueryDetails,
  usePreOpSurveys,
  useQOLSurveys,
  usePostOpSurveys
}
